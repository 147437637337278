.home-button {
  width: 40%;
  padding-left: inherit;
  padding-right: inherit;
  background: #18A2E0;
}

.home-cluster.flipped {
  margin-left: 5vw;
}

.home-cluster.flipped > button {
  padding-left: 40px;
  padding-right: 40px;
  width: 50%;
}

.home-cluster.extra-bottom-pad {
  margin-bottom: 4rem;
}

.home-button.wider {
  width: 50%;
}

.learn-more-div {
  margin-top: 15vh;
  margin-bottom: 3vh;
}

.home-image {
  border-radius: 10px;
  width: 35vw;
  height: auto;
}

.home-image.mobile {
  display: none;
}

.testimonials {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}

.testimonials-header {
  margin-top: 10vh;
  margin-bottom: 1.5rem;
}

.home-cluster {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

@media screen and (max-width: 800px) {
  .home-cluster {
    display: grid!important;
    margin-top: inherit;
    margin-bottom: 5vh;
  }
  
  .home-cluster > div > b,
  .home-cluster > div > p {
    width: 100%!important;
  }

  .home-button,
  .home-button.wider {
    width: 100%;
  }

  .home-cluster.flipped {
    margin: 0!important;
  }
  
  .home-image {
    display: none;
    margin-bottom: 3vh;
    width: fit-content;
    /* height: auto; */
  }

  .home-image.mobile {
    display: inherit!important;
  }

  .testimonials {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .testimonials > div {
    margin-bottom: 2rem;
    width: 90%!important;
  }
}
