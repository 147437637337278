.fitter-profile {
    margin-right: 5vw;
    margin-left: 5vw;
    text-align: left;
}

.cal-fire-info {
    text-align: left;
    margin-top: 0!important;
}

.cal-fire-info > .row > div > h2,
.cal-fire-info > .row > div > p {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;
}

.cal-fire-info > .row > div > p {
    padding-right: 0.75rem;
}

.ceu-description {
    width: 40vw;
}

.ceu-image {
    border-radius: 0.5rem;
    max-height: 10vh;
    width: 29vw;
    object-fit: cover;
}

.ceu-image-wrapper {
    width: 30vw;
    text-align: center;
    border-radius: 0.5rem;
    border: 0.5px dashed darkgrey;
    padding: 5px;
    padding-bottom: 0;
}

.image-cal {
    max-width: 70vw;
}

.cal-fire-img {
    height: auto;
    width: 40vw;
}

@media screen and (max-width: 600px) {
    .cal-fire-info > .row {
      flex-direction: column;
    }
    
    .cal-fire-info > .row > div > p {
        padding-right: 0;
    }

    .flexy-ceu-card {
        flex-direction: column;
    }

    .ceu-description {
        width: 100%;
        margin-bottom: 2vh;
    }

    .ceu-image-wrapper {
        width: 60vw;
    }

    .ceu-image {
        width: 100%;
    }

    .cal-fire-img {
        width: 100%;
    }
}