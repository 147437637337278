.profile {
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5vh;
}

.profile-table {
    width: 100%;
    border-spacing: 0 4vh;
    border-collapse: separate;
}

.profile-button {
    background: transparent;
    border: 2px solid #4450A2;
    color: #4450A2;
    padding-top: 0;
    padding-bottom: 3px;
    white-space: nowrap;
    height: min-content;
    margin-right: 2vw;
}

.profile-button.danger {
    border-color: #DC5252;
    color: #DC5252;
}

.profile-input {
    width: 90%!important;
}

.address {
    border-spacing: 3px 3px;
    border-collapse: separate;
}

.address-input {
    width: 100%!important;
}

.narrow {
    width: 80px!important;
}

.state-select {
    min-width: max-content!important;
}

.table-item:not([colspan]) {
    overflow: hidden!important;
    width: 28vw!important;
    height: max(10vh, min-content)!important;
}

.d-block.double-name {
    width: 47.5%;
}

.address-mobile-row {
    display: none!important;
}

.mobile-only {
    display: none;
}

.profile-mobile-row {
    display: none;
}

@media screen and (max-width: 750px) {
    .address-mobile-collapse {
        display: none!important;
    }
    
    .address-mobile-row {
        display: block!important;
    }
}

@media screen and (max-width: 1100px) {
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: grid;
    }

    .profile-mobile-row {
        display: table-row;
    }

    .table-item.mobile-only {
        width: 100%!important;
    }

    .d-block:has(.profile-input.double-name) {
        width: 47.5%!important;
    }
}

@media screen and (max-width: 600px) {
    .profile-inline-row,
    .profile-mobile-row {
        display: grid!important;
    }

    .profile-input,
    .table-item:not([colspan]) {
        width: 90vw!important;
        margin-bottom: 1vh;
    }

    .d-block:has(.profile-input.double-name) {
        width: 45vw!important;
    }

    .profile-input.double-name {
        width: 100%!important;
    }

    .profile-button {
        margin-bottom: 3vh;
        width: 90vw;
    }
}