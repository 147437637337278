.course-table-wrapper {
    overflow-x: scroll;
    /* overflow-y: scroll; */
    max-height: 90vh;
}

.course-table .smaller-text {
    font-size: 0.8rem;
}

.course-table th,
.course-table td {
    padding: 0.25rem;
    /* text-align: center!important; */
}

.course-table th {
    vertical-align: bottom;
}

.course-table td {
    text-align: center!important;
    font-weight: bold;
    border: 2px solid gray;
    z-index: 10;
}

.course-table th.no-scroll {
    min-width: 15rem;
}

.course-table td.no-scroll {
    position: sticky;
    width: 15rem;
    left: 0;
}

.course-table thead tr {
    min-height: 400px;
    /* transform: translate(28px, 0); */
}

.course-table thead {
    height: 400px;
    /* position: absolute; */
}

.course-table .course-header {
    writing-mode: vertical-lr;
    /* transform: rotate(180deg); */
    /* transform-origin: 200% 50%; */
    transform: rotate(180deg);
    position: sticky;
    top: 0;
    z-index: 3;
    /* transform-origin: center; */
}

.course-table th:not(.course-header) {
    /* background: black; */
    /* color: white; */
    /* transform: translate(-28px, 0); */
    text-align: center!important;
}

.course-table .blackout {
    background: black;
    color: white;
}

.course-table .level-divider {
    min-width: 1rem;
    max-width: 1.2rem;
    border: white;
}

.course-table .rti-input {
    width: 100%;
    /* height: 100%; */
    border: none;
    border-radius: 0;
    padding: 0;
    text-align: center;
    background-color: inherit;
}

.course-header #rti-table-header {
    background: black;
}

.course-table .rti-input-cell {
    padding: 0;
    vertical-align: middle;
}

.rti-context-menu {
    /* position: absolute; */
    background: white;
    padding: 0;
    border-radius: 0.25rem;
    border: 1px solid gray;
}

.rti-context-menu.inactive {
    display: none;
}

.rti-context-menu.active,
.rti-context-menu.active div {
    display: grid!important;
}

.rti-enrollment-button {
    border-radius: 0;
    color: black;
}

.rti-enrollment-button.yellow {
    background-color: rgb(255, 255, 91);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.rti-enrollment-button.orange {
    background-color: rgb(252, 189, 70);
}

.rti-enrollment-button.green {
    background-color: rgb(73, 218, 73);
}

.rti-enrollment-button.blue {
    background-color: rgb(76, 76, 241);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}