.container {
    width: 80vw;
    height: 70vh;
}
.header {
    text-align: start;
    font-weight: bold;
}
.accordion {
    height: 100%;
    align-items: stretch;

}
.row {
    align-items: center;
}
.question {
    height: 7vh;
    align-items: center;
    border: 1px solid red;
}
.answer {
    width: 100%;
    height: 0px;
    opacity: 0;
    padding-left: 10vw;
    transition: height 200ms opacity 200ms;
}

.answer.expanded {
    border: 1px solid blue;
    padding: 500px;
    opacity: 1;
}