.blog-post-title {
    margin-bottom: .25rem;
    font-size: 2.5rem;
}

.home-images {
    object-fit: cover;
}

.footer-title {
    font-style: italic !important;
    font-size: 2rem;
    font-weight: bold;
}

.mission {
    font-size: 26px;
    text-align: inherit;
    margin: 5px;
    text-align: justify;
}