header {
    font-weight: 500;
}

.header-logo {
    width: 130px;
    min-width: 80px;
    height: auto;
}

.link-bar {
    display: block;
    align-items: center;
}

.link-bar > a {
    margin-right: 2vw;
    color: black!important;
}

.link-bar > button {
    white-space: nowrap;
}

.link-bar.wide {
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 20px!important;
}

.link-bar.wide > a {
    line-height: 5vh;
}

.link-bar > .selected,
.link-bar > .sign-up,
.link-bar > .call {
    color: #485fc7!important;
}

.link-bar > .selected,
.link-bar > .call {
    text-underline-offset: 2vh;
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.link-bar > .call {
    text-underline-offset: 0.5vh;
    text-decoration-thickness: 1px;
}

footer {
    padding: 3vw;
    color: white;
    background-color: #4450A2;
}

.footer-group {
    display: grid;
    text-align: left;
}

footer > span,
.footer-group > a,
.footer-group > span {
    font-weight: 300;
}

.footer-logo {
    width: 10vh;
    margin-bottom: 1vh;
    height: auto;
}

.footer-body {
    width: 75%!important;
}

.header-desktop {
    padding: 2vw;
}

.header-mobile {
    display: none!important;
}

.hamburger-header {
    display: none!important;
}
/* 
.settings-cog {
    width: 22px;
    height: auto;
    margin-left: 8px;
} */

@media screen and (max-width: 1000px) {
    .header-desktop {
        display: none!important;
    }

    .header-mobile {
        display: flex!important;
        background-color: #4450A2;
        padding: 2vw;
    }

    .header-logo {
        width: 100px;
    }

    .link-bar.wide > a {
        margin-right: 6vw;
        line-height: 45px;
    }
    
    .hamburger-icon {
        width: 60px;
        padding: 10px;
        border: 2.5px solid white;
        border-radius: 7px;
    }

    .hamburger-header {
        transition: max-height 0.5s ease-in-out;
        display: grid!important;
        visibility: hidden;
        max-height: 0;
        overflow: hidden;
    }

    .hamburger-header.expanded {
        max-height: 500px;
        visibility: visible;
        background-color: #36373C;
    }
    
    .hamburger-header.expanded > .link-bar.wide {
        margin-top: 3vh;
        justify-content: center;
    }

    .hamburger-header.expanded > div > a,
    .hamburger-header.expanded > div > div > a {
        color: white!important;
    }

    .footer-body {
        display: grid!important;
    }

    .footer-logo {
        margin-bottom: 0;
        width: 13vh;
    }
    
    .header-sign-up-button {
        padding-left: 5vw!important;
        padding-right: 5vw!important;
        width: fit-content!important;
    }

    .mailing-list-link {
        display: none;
    }
}