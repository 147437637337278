.min-textarea-height {
    min-height: 150px!important;
}

.admin-functionality {
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;
}

.break-width {
    width: 100%;
    margin: auto;
}

.pending-text {
    display: flex;
    color: white;
}

.justify-content-center.mt-3.card {
    width: 95% !important;
    margin: auto;
    text-align: initial;
}

.row.certifications.bottom {
    height: 450px;
}

.justify-content-center.mt-3.card.bottom {
    margin: auto;
    text-align: initial;
}

.sub-info.licenses-link.card {
    width: 45%;
}

.card-position {
    display: block;
}


.sub-info.licenses-link.card.mt-3 {
    width: 100%;
    margin: auto;
}

.highlight-change {
    background-color: rgb(206, 69, 69);
    color: white;
}

.view-license-image {
    margin: auto;
    display: block;
}

.admin-buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px
}

.current-admin {
    height: auto;
}

.current-applicants {
    height: auto;
}

.drag-and-drop.bg-danger {
    padding: 10px;
    color: white;
}

.drag-and-drop.bg-primary {
    padding: 10px;
    color: white;
}

.drag-boxes {
    display: none;
}

.start-date {
    display: grid;
}

.end-date {
    display: grid;
}

.header-color {
    background: #1b1b1b;
    color: white;
}

.enroll-single.sub-italic {
    margin-top: -5px;
    margin-bottom: 3px;
    font-style: italic;
    font-size: 0.8rem;
}

.enroll-single.check-centered {
    margin-top: auto;
    margin-bottom: auto;
}

.enroll-single.header {
    font-weight: bold;
    font-size: 1.3rem;
}

.enroll-single.center-header {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: -5px;
}

select.enroll-single {
    width: 100%;
    height: fit-content;
    margin-top: 0.5rem;
}

.enroll-single.course-label {
    bottom: -2px;
}

.onboard.company-form {
    width: 56%;
    margin-inline: auto;
    padding: 1vh;
    margin-top: 2vh;
    display: flex;
    justify-content: left;
}

.onboard.admin-quote-header {
    font-weight: bold;
    font-size: 1.7rem;
    margin-right: 0vw;
}

.select-highlight {
    box-shadow: 0 0 20px #4450A2;
    border: 2px solid #11111188;
}

.expandable {
    height: auto;
    max-height: 4.5rem;
    overflow: hidden;
    transition: max-height 300ms ease-in-out
}

.expandable.select-highlight {
    max-height: 100vh
}

@media (min-width: 992px) {
    .break-width {
        width: 50%;
    }

    .row.certifications.top {
        width: 50%;
    }

    .cert-list {
        display: inline-flex;
        width: 100%;
    }

    .justify-content-center.mt-3.card {
        width: 48% !important;
        margin: auto;
        text-align: initial;
    }

    .sub-info.licenses-link.card.mt-3 {
        width: 45%;
        margin: auto;
    }
    
    .card-position {
        display: flex;
    }
    
    .view-license-image {
        margin: auto;
        display: block;
    }
    
    .drag-and-drop.bg-danger {
        width: 100%;
        float: left;
        height: 80px;
    }
    
    .drag-and-drop.bg-primary {
        width: 100%;
        height: 80px;
    }

    .input-group.applicants {
        width: 20rem;
    }

    .current-admin {
        display: inline-block;
        height: auto;
        margin-right: 1.25rem!important;
        width: 100%;
    }
    
    .drag-boxes {
        display: block;
    }

    .certification-search {
        width: 50%;
    }
    
    .certification-search.bottom {
        width: 100%;
    }

    .job-button {
        margin-left: 10px;
    }

    
    .pending-text {
        display: flex;
        padding: 15px;
    }

    .current-applicants {
        min-height: 50vh;
    }
}