.news-feed {
  text-align: left;
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-footer {
  position: absolute;
  bottom: 1.5rem;
  text-align: center;
}

.form-background.news {
  width: 40vw;
}

.card-container.news {
  width: 90vw;
  margin: auto;
}

.news-image {
  float: left;
  width: 30vw;
  margin-right: 1rem;
  border-radius: 2vw;
}

.news-image.alt-float {
  float: right;
  height: auto;
  margin-left: 1rem;
  margin-right: 0;
}

.news-content {
  text-align: justify;
}

@media screen and (max-width: 700px) {
  .news-image {
    width: 100%;
    border-radius: 3vw;
  }
}