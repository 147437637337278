.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.App-router {
    flex: 1;
    width: 100vw;
    min-height: 70vh;
}

.App-router > div {
    min-height: 70vh;
}

/* * {
  outline: 1px solid aqua;
} */

button {
  border-radius: 5px;
  background: #4450A2;
  border: none;
  padding: 6px 40px;
  color: white;
}

button:disabled {
  background: white;
  border: 2px solid gray;
  color: gray;
  padding: 4px 40px;
}

button.act-btn-outline {
    background-color: #4450A277;
    border: 1px solid #6570C2;
}

input:not(
  [type=checkbox],
  [type=file],
  .form-control),
select:not(.form-control) {
  border-radius: 0.3rem;
  border: 1px solid black;
  height: 2.0em;
  padding-right: 3px;
  padding-left: 10px;
  width: min(290px, 22vw);
}

select:not(.form-control) {
  padding-top: 3px;
}

input[disabled]:not(
  [type=checkbox],
  [type=file],
  .form-control),
select[disabled]:not(.form-control) {
  background-color: lightgray;
}

a {
  color: #4450A2!important;
}

hr {
  width: 100%;
  margin-top: 0;
  margin-bottom: 2vh;
  background-color: black!important;
}

.wide-popup {
  min-width: max(28vw, 360px)!important;
  overflow: hidden;
}

.extra-wide-popup {
  /* min-width: max(32vw, 500px)!important; */
  width: max(45vw, 360px)!important;
  max-width: 500px!important;
  overflow: hidden;
}

.popup-scrollable-content {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}