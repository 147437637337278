.sub-control-cluster {
    text-align: center;
    width: min-content;
    white-space: nowrap;
}

.sub-control-cluster.left > div {
    text-align: left;
    margin-bottom: 2vh;
}

.sub-control-cluster.left > button {
    margin-top: 1vh;
}
