.auth-modal {
    padding: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    border-radius: 1rem;
}

.sign-up-label {
    font-size: smaller;
    font-weight: 300;
    margin-bottom: 2px;
}

.sign-up-check {
    margin-right: 1vw;
    transform: scale(1.5);
    /* height: 30px;
    background: black; */
}
.sign-up-check.tight  {
    margin-right: 0.8vw!important;
    margin-left: 0!important;
}

.sign-up-check-label {
    margin-bottom: 2px;
}

.sign-up-button {
    background: #4450A2;
    /* margin-top: 1rem; */
    width: min(290px, 22vw); 
}

.sign-up-paragraph {
    width: calc(2 * (min(290px, 22vw)));
    margin-bottom: 0.5rem;
    font-size: smaller;
}

.go-back-button {
    background: gray;
}

.login-button {
    width: 100%!important;
}

.card-fail {
    width: min(290px, 22vw);  
}

.card-fail.no-button {
    margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
    .forgot-header {
        display: grid!important;
        text-align: start;
        margin-bottom: 1vh;
    }

    .go-back-button {
        margin-top: 1vh;
        width: 70%;
    }
    
    .sign-up-button {
        width: 100%!important;
    }

    .sign-up-button-cluster {
        flex-direction: column;
        align-items: center;
    }
    
    .sign-up-paragraph {
        width: 100%!important;
    }

    input,
    select {
        width: 100%!important;
    }

    .input-group {
        display: grid!important;
    }
    
    .pass-controls {
        flex-direction: column;
        align-items: flex-start!important;
    }

    .pass-controls > div {
        /* padding: 0!important; */
        width: min-content!important;
        white-space: nowrap;
    }

    .sign-up-check {
        margin-right: 3vw!important;
        width: min-content!important;
    }

    .sign-up-check.tight {
        margin-right: 2vw!important;
    }

    .sign-up-check-label {
        font-size: 0.8em;
    }

    .auth-modal {
        overflow-y: scroll;
        max-height: 90vh;
        max-width: 90vw;
        padding-left: 2em!important;
        padding-right: 2em!important;
    }

    .no-scroll {
        overflow-y: hidden!important;
    }

    .was-renewed {
        margin-top: 1vh;
        margin-bottom: 1vh;
    }
}