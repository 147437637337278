.enroll-button {
    padding-left: 4vw!important;
    padding-right: 4vw!important;
}

.portal-button {
    margin-bottom: 2vh;
}

.header-and-tokens {
    display: flex;
    justify-content: start;
    align-items: center;
}

.vw-90 {
    width: 90vw;
}

@media screen and (max-width: 750px) {
    .portal-button {
        width: 100%!important;
    }
    
    .header-and-tokens {
        display: grid;
    }

    .header-and-tokens > i {
        margin-left: 0!important;
    }

    .purchase-token-button {
        width: 90vw;
    }
}