
.attendee-table > tr > td {
    border-top: 1px solid darkgray;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.attendee-table > tr > th {
    background: lightgray;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.attendee-table > tr:nth-child(odd) > td {
    background: lightgray;
}

.events-page {
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;
}

.event-popup {
    width: min(65vw, 800px);
    max-height: 650px;
}

.event-columns {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    height: min-content;
    gap: 20px;
}

.event-card {
    width: 49%;
}

.event-description {
    font-weight: 500;
    max-height: 8.5rem;
    overflow: hidden;
}

.event-description > p {
    text-overflow: ellipsis;
}

.event-expired {
    background-color: lightgray;
}

.event-expired p,
.event-expired h3 {
    /* text-decoration: line-through; */
}

.event-registered {
    border: .25rem solid #aaffbb55;
    box-shadow: 0 0 2rem 1rem #aaffbb88;
    background: #aaccaaff;
}

.event-registered button {
    border: 2px solid #00770088;
    background: #aaffaa77;
    color: #00770088;
    font-weight: bold;
}

.event-funnel {
    padding-left: .75rem;
    padding-right: .75rem;
    height: 2.3rem;
    display: flex;
    align-items: center;
    background: lightgray;
    border-radius: .25rem;
}

.event-funnel svg {
    height: 1.3rem;
    width: auto;
    fill: gray;
}

.input-dollar {
    font-size: large;
    padding-top: 3px;
    padding-left: 6px;
    margin-right: 0;
}

.benefit-divider {
    /* width: 98%; */
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
}

.benefit-divider:last-of-type {
    display: none;
}

.benefit-pm-icon {
    font-size: x-large;
    cursor: pointer;
}

.cost-input {
    background: center left / 20px no-repeat url('/public/dollar-sign.png');
    padding-left: 20px;
}

.registration-description {
    max-height: 4.5rem;
    overflow: hidden;
}

.info-phone {
    height: 1rem;
    width: auto;
    filter: invert(49%) sepia(10%) saturate(389%) hue-rotate(167deg) brightness(89%) contrast(88%);
}

.btn:hover>div>.info-phone {
    filter: invert(100%) sepia(0%) saturate(7471%) hue-rotate(67deg) brightness(109%) contrast(97%);
}

.event-registration-flex {
    flex-direction: row;
    align-items: stretch;
}

.event-details-flex {
    flex-direction: column;
    justify-content: space-between;
    background: black;
}

.location-margin {
    margin-top: 1.5rem;
}

.circle-question {
    height: 1rem;
    width: 1rem;
}

.help-button > .help-button-number {
    display: none;
}
.help-button:hover > .help-button-number,
.help-button:focus > .help-button-number {
    display: inherit;
}
.help-button:hover > .help-button-content,
.help-button:focus > .help-button-content {
    display: none;
}

.attendee-header-icon {
    height: 1.5em;
}

.canceled-attendee {
    background: gray;
    text-decoration: line-through;
}

.event-button-group svg {
    height: 1.2rem!important;
    width: auto;
    margin-bottom: .25rem;
}

@media screen and (max-width: 1109px) {
    .event-registration-flex {
        flex-direction: column;
    }

    .event-details-flex {
        flex-direction: row;
        justify-content: space-around;
        width: 100%!important;
    }
    
    .location-margin {
        margin-top: 0;
    }

    .event-popup {
        width: 100%!important;
    }
}

@media screen and (max-width: 1315px) {
    .event-card {
        width: 100%;
    }
}