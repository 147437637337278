
        ul.lst-kix_list_1-0 {
            list-style-type: none
        }

        ol.lst-kix_list_9-0.start {
            counter-reset: lst-ctn-kix_list_9-0 0
        }

        .lst-kix_list_9-1>li {
            counter-increment: lst-ctn-kix_list_9-1
        }

        .lst-kix_list_3-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_5-7 {
            list-style-type: none
        }

        ul.lst-kix_list_5-8 {
            list-style-type: none
        }

        .lst-kix_list_3-1>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_3-2>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_5-5 {
            list-style-type: none
        }

        ul.lst-kix_list_5-6 {
            list-style-type: none
        }

        .lst-kix_list_8-1>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_8-2>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_9-0>li {
            counter-increment: lst-ctn-kix_list_9-0
        }

        ul.lst-kix_list_1-3 {
            list-style-type: none
        }

        .lst-kix_list_3-5>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_5-0 {
            list-style-type: none
        }

        ul.lst-kix_list_1-4 {
            list-style-type: none
        }

        ul.lst-kix_list_1-1 {
            list-style-type: none
        }

        .lst-kix_list_3-4>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_1-2 {
            list-style-type: none
        }

        ul.lst-kix_list_5-3 {
            list-style-type: none
        }

        ul.lst-kix_list_1-7 {
            list-style-type: none
        }

        .lst-kix_list_3-3>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_5-4 {
            list-style-type: none
        }

        ul.lst-kix_list_1-8 {
            list-style-type: none
        }

        ul.lst-kix_list_5-1 {
            list-style-type: none
        }

        .lst-kix_list_8-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_1-5 {
            list-style-type: none
        }

        ul.lst-kix_list_5-2 {
            list-style-type: none
        }

        ul.lst-kix_list_1-6 {
            list-style-type: none
        }

        .lst-kix_list_8-7>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_3-8>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-6.start {
            counter-reset: lst-ctn-kix_list_9-6 0
        }

        .lst-kix_list_8-5>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_8-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_8-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_3-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_3-7>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_8-4>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-3.start {
            counter-reset: lst-ctn-kix_list_9-3 0
        }

        .lst-kix_list_8-8>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_9-2>li {
            counter-increment: lst-ctn-kix_list_9-2
        }

        .lst-kix_list_5-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_4-8>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_5-3>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-5.start {
            counter-reset: lst-ctn-kix_list_9-5 0
        }

        .lst-kix_list_4-7>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_5-2>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_5-1>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_4-8 {
            list-style-type: none
        }

        .lst-kix_list_5-7>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_8-4 {
            list-style-type: none
        }

        ul.lst-kix_list_8-5 {
            list-style-type: none
        }

        ul.lst-kix_list_4-6 {
            list-style-type: none
        }

        .lst-kix_list_5-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_5-8>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_8-2 {
            list-style-type: none
        }

        ul.lst-kix_list_4-7 {
            list-style-type: none
        }

        ul.lst-kix_list_8-3 {
            list-style-type: none
        }

        ul.lst-kix_list_8-8 {
            list-style-type: none
        }

        ul.lst-kix_list_8-6 {
            list-style-type: none
        }

        ul.lst-kix_list_8-7 {
            list-style-type: none
        }

        .lst-kix_list_9-4>li {
            counter-increment: lst-ctn-kix_list_9-4
        }

        ul.lst-kix_list_4-0 {
            list-style-type: none
        }

        ul.lst-kix_list_4-1 {
            list-style-type: none
        }

        .lst-kix_list_5-4>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_4-4 {
            list-style-type: none
        }

        .lst-kix_list_5-5>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_8-0 {
            list-style-type: none
        }

        ul.lst-kix_list_4-5 {
            list-style-type: none
        }

        ul.lst-kix_list_8-1 {
            list-style-type: none
        }

        ul.lst-kix_list_4-2 {
            list-style-type: none
        }

        ul.lst-kix_list_4-3 {
            list-style-type: none
        }

        .lst-kix_list_6-1>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-4>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-2>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-8>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-5>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-7>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_7-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_6-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-7>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-7.start {
            counter-reset: lst-ctn-kix_list_9-7 0
        }

        .lst-kix_list_7-4>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_7-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-4>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-5>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-8>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_7-1>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_7-5>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_9-6>li {
            counter-increment: lst-ctn-kix_list_9-6
        }

        .lst-kix_list_7-2>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_7-3>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_7-5 {
            list-style-type: none
        }

        ul.lst-kix_list_7-6 {
            list-style-type: none
        }

        ul.lst-kix_list_7-3 {
            list-style-type: none
        }

        ol.lst-kix_list_9-4.start {
            counter-reset: lst-ctn-kix_list_9-4 0
        }

        .lst-kix_list_9-7>li {
            counter-increment: lst-ctn-kix_list_9-7
        }

        ul.lst-kix_list_3-7 {
            list-style-type: none
        }

        ul.lst-kix_list_7-4 {
            list-style-type: none
        }

        ul.lst-kix_list_3-8 {
            list-style-type: none
        }

        ol.lst-kix_list_9-7 {
            list-style-type: none
        }

        ol.lst-kix_list_9-8 {
            list-style-type: none
        }

        ul.lst-kix_list_7-7 {
            list-style-type: none
        }

        ul.lst-kix_list_7-8 {
            list-style-type: none
        }

        ol.lst-kix_list_9-3 {
            list-style-type: none
        }

        ul.lst-kix_list_3-1 {
            list-style-type: none
        }

        ol.lst-kix_list_9-4 {
            list-style-type: none
        }

        ul.lst-kix_list_3-2 {
            list-style-type: none
        }

        ol.lst-kix_list_9-5 {
            list-style-type: none
        }

        .lst-kix_list_7-8>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-6 {
            list-style-type: none
        }

        ul.lst-kix_list_3-0 {
            list-style-type: none
        }

        ul.lst-kix_list_7-1 {
            list-style-type: none
        }

        ul.lst-kix_list_3-5 {
            list-style-type: none
        }

        ul.lst-kix_list_7-2 {
            list-style-type: none
        }

        ol.lst-kix_list_9-0 {
            list-style-type: none
        }

        ul.lst-kix_list_3-6 {
            list-style-type: none
        }

        ol.lst-kix_list_9-1 {
            list-style-type: none
        }

        ul.lst-kix_list_3-3 {
            list-style-type: none
        }

        ul.lst-kix_list_7-0 {
            list-style-type: none
        }

        .lst-kix_list_7-7>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-2 {
            list-style-type: none
        }

        ul.lst-kix_list_3-4 {
            list-style-type: none
        }

        .lst-kix_list_9-5>li {
            counter-increment: lst-ctn-kix_list_9-5
        }

        .lst-kix_list_9-8>li {
            counter-increment: lst-ctn-kix_list_9-8
        }

        .lst-kix_list_4-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_4-1>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_4-4>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_9-2>li:before {
            content: " "
        }

        .lst-kix_list_4-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_4-5>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_4-2>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_4-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_9-3>li:before {
            content: " "
        }

        .lst-kix_list_9-0>li:before {
            content: " "
        }

        .lst-kix_list_9-1>li:before {
            content: " "
        }

        .lst-kix_list_9-7>li:before {
            content: " "
        }

        ol.lst-kix_list_9-2.start {
            counter-reset: lst-ctn-kix_list_9-2 0
        }

        .lst-kix_list_9-6>li:before {
            content: " "
        }

        .lst-kix_list_9-3>li {
            counter-increment: lst-ctn-kix_list_9-3
        }

        .lst-kix_list_9-4>li:before {
            content: " "
        }

        .lst-kix_list_9-5>li:before {
            content: " "
        }

        ul.lst-kix_list_6-6 {
            list-style-type: none
        }

        ul.lst-kix_list_6-7 {
            list-style-type: none
        }

        ul.lst-kix_list_6-4 {
            list-style-type: none
        }

        ul.lst-kix_list_2-8 {
            list-style-type: none
        }

        ul.lst-kix_list_6-5 {
            list-style-type: none
        }

        ul.lst-kix_list_6-8 {
            list-style-type: none
        }

        ul.lst-kix_list_2-2 {
            list-style-type: none
        }

        .lst-kix_list_1-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_2-3 {
            list-style-type: none
        }

        ul.lst-kix_list_2-0 {
            list-style-type: none
        }

        ul.lst-kix_list_2-1 {
            list-style-type: none
        }

        ul.lst-kix_list_6-2 {
            list-style-type: none
        }

        .lst-kix_list_9-8>li:before {
            content: " "
        }

        ul.lst-kix_list_2-6 {
            list-style-type: none
        }

        ul.lst-kix_list_6-3 {
            list-style-type: none
        }

        .lst-kix_list_1-1>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_1-2>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_list_2-7 {
            list-style-type: none
        }

        ul.lst-kix_list_6-0 {
            list-style-type: none
        }

        ul.lst-kix_list_2-4 {
            list-style-type: none
        }

        ul.lst-kix_list_6-1 {
            list-style-type: none
        }

        ul.lst-kix_list_2-5 {
            list-style-type: none
        }

        .lst-kix_list_1-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_1-4>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_1-7>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_1-5>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_1-6>li:before {
            content: "\0025cf  "
        }

        li.li-bullet-0:before {
            margin-left: -14.2pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 14.2pt
        }

        ol.lst-kix_list_9-1.start {
            counter-reset: lst-ctn-kix_list_9-1 0
        }

        .lst-kix_list_2-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-1>li:before {
            content: "\0025cf  "
        }

        ol.lst-kix_list_9-8.start {
            counter-reset: lst-ctn-kix_list_9-8 0
        }

        .lst-kix_list_1-8>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-2>li:before {
            content: "\0025cf  "
        }

        .lst-kix_list_2-3>li:before {
            content: "\0025cf  "
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c20 {
            -webkit-text-decoration-skip: none;
            color: #0563c1;
            font-weight: 400;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: "Liberation Serif";
            font-style: normal
        }

        .c4 {
            -webkit-text-decoration-skip: none;
            color: #000000;
            font-weight: 400;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c3 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.2;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 18pt
        }

        .c0 {
            margin-left: 3.8pt;
            padding-top: 0pt;
            padding-left: -3.8pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c6 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c15 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 24pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c8 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.2;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c8-spacing {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 10pt;
        }

        .c11 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Liberation Serif";
            font-style: normal
        }

        .c2 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c13 {
            padding-top: 12pt;
            padding-bottom: 6pt;
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c14 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 8pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c17 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c1 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c18 {
            padding-top: 0pt;
            padding-bottom: 7pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c10 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c19 {
            background-color: #ffffff;
            max-width: 498.6pt;
            padding: 56.7pt 56.7pt 56.7pt 56.7pt
        }

        .c7 {
            padding: 0;
            margin: 0
        }

        .c16 {
            color: inherit;
            text-decoration: inherit
        }

        .c12 {
            height: 24pt
        }

        .c5 {
            height: 12pt
        }

        .c9 {
            height: 14pt
        }

        .title {
            padding-top: 24pt;
            color: #000000;
            font-weight: 700;
            font-size: 36pt;
            padding-bottom: 6pt;
            font-family: "Liberation Serif";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 18pt;
            color: #666666;
            font-size: 24pt;
            padding-bottom: 4pt;
            font-family: "Georgia";
            line-height: 1.0;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }

    