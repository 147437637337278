.small-font {
    font-size: small;
}

.super-functionality {
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;
}

.nav-logo {
    height: 125px;
    margin-left: 20px;
}

.collapsed-cert {
    height: fit-content;
}

.button-placement {
    display: flex;
    float: right;
    width: 100%;
    justify-content: space-between;
}

.button-placement.right {
    display: flex;
    float: right;
    width: 40%;
}

.correction.header {
    font-weight: bold;
    font-size: 1.3rem;
}

.correction.italic {
    font-style: italic;
}

.row.certifications {
    /* margin: 1rem; */
    display: inline;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 5px;
    margin-right: 5px;
    height: 600px;
}

.card-header.custom {
    height: 3rem;
}

.highlight-change {
    background-color: rgb(206, 69, 69);
    color: white;
}

.field-button-align {
    display: flex;
}

.card-header.header-container {
    color: white;
    background-color: #404654;
    margin-left: 5px;
    margin-right: 5px;
}

.card-header.pending-text {
    background-color: grey;
    border-top-right-radius: 0px !important;
}

.quote-information {
    text-align: initial;
}

.view-image {
    margin: auto;
}

.close-size {
    font-size: 45px;
}

.popup-container {
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(73, 73, 73);
    border-radius: 10px;
    overflow-y: scroll;
    height: 800px;
}

.collapsing {
    transition: .5s linear !important;
}

.ceu-buttons {
    display: contents;
    justify-content: space-between;
}

.tab {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.color-coded-text {
    color: #363636;
}

.color-code-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #d7d7d7;
}

.mydivider {
    border: none;
    border-bottom: 1px solid transparent;
}

.select-admin {
    margin-left: 10px;
}

.unpaid-account {
    content: " ";
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000066;
}

.tab-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 30px;
    background: white;
    border-radius: 5px;
    color:black;
    font-size: x-large;
}

.reactivate-checkbox {
    width: min-content!important;
    margin-bottom: 1px;
}

.tab-title.apprentice {
    height: 40px;
    min-width: 40px;
    background-color: black;
    color: white;
}

.input-group.rounded.w-50.apprentice,
.input-group.rounded.w-50.apprentice > input,
.input-group.rounded.w-50.apprentice > span {
    height: 40px!important;
}

.loading-absorb {
    display: flex;
    width: 300px;
    align-items: center;
}

.loading-absorb-gif {
    height: 30px;
    width: 30px
}

.individual-row {
    display: flex;
}

.active-sub-info {
    min-width: 314px;
    margin: 0;
    padding-right: 10px;
}

.striked {
    text-decoration: line-through;
}

.select-highlight {
    box-shadow: 0 0 20px #4450A2;
    border: 2px solid #11111188;
}

.expandable {
    height: auto;
    max-height: 4em;
    overflow: hidden;
    transition: max-height 300ms ease-in-out
}

.expandable.select-highlight {
    max-height: 100vh;
}

.hide-text,
.hide-text strong {
    color: transparent!important;
}

.min-vh-50 {
    min-height: 50vh;
}

input.reset-show-password {
    width: min-content!important;
}

@media (max-width: 1140px) {
    .individual-row {
        display: inline-grid;
    }
}

@media (min-width: 992px) {
    
    /* .break {
        width: 95%;
    } */
    .pending-admins {
        width: 50%;
    }

    .bottom-pending {
        display: inline-flex;
        width: 100%;
    }

}

@media (min-width: 775px) {
    .cert-row-resize {
        width: 12% !important;
    }

    .select-admin {
        margin-left: 10px !important;
    }
}

