.enrollment.divider {
    border-top: solid;
    border-color: #a9b9d6;
    border-width: 3px;
    margin-top: 0.8vh;
}

.enrollment.fixed-divider {
    border-top: solid;
    border-color: #a9b9d6;
    border-width: 3px;
    margin-top: -0.1vh;
    /* margin-inline: -0.3vw; */
}

.enrollment.header {
    font-size: 1.5rem;
    margin-left: -0.2rem;
}

.enrollment.user {
    margin-top: -0.2vh;
    margin-bottom: 0.4vh;
    margin-left: -0.15rem;
}

.enrollment.course-row {
    margin-top: -1.8vh;
}

.privacy.header {
    font-size: 2em;
    font-style: bold;
}

.privacy.sub-header {
    font-size: 1.4em;
    font-style: bold;
}

.table-display.container {
    display: table;
    width: 100vw;
}enrollment header

.table-display.table-container {
    width: 100vw;
    align-content: center
}

.table-display.header-row {
    display: table-row;
    width: 90%;
}

.table-display.header {
    font-size: 1.3rem;
}

.table-display.switch-view {
    margin-top: -0.2rem;
    margin-bottom: 0.8rem;
}

.table-display.data-row {
    display: table-row;
    width: 333px;
}

.table-display.data-cell {
    display: table-cell;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: fit-content;
}

.table-display.wide-button {
    width: 12rem;
}

.overflow-questions {
    height: 150px;
    overflow-y: scroll;
}

.denial-buttons {
    display: flex;
}

.user-info-content {
    text-align: right;
}