.decrease-button {
  transition: fill 250ms;
}

.navbar-toggler.nav-button {
  margin-left: 20px;
}

.navbar-nav.mobile-nav {
  text-align: left;
  margin-left: 1.5rem;
}

.bold {
  font-weight: bold;
}

.buttons {
  padding: 10px;
}

.absorb.course-header {
  display: grid!important;
  text-align: left;
}

.absorb.course-header > button {
  margin-top: 2vh;
  width: 25%;
}

.absorb.header-controls {
  display: flex;
}

.absorb.tokens {
  width: fit-content;
}

.absorb.all-courses {
  font-size: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}

.absorb.token-bundle {
  margin-top: 0.6rem;
  margin-bottom: auto;
  display: block !important;
}

.absorb.employee-token-view {
  width: 200px;
  margin-top: auto;
  margin-bottom: auto;
}

.absorb.admin-card {
  width: 400px;
}

.absorb.admin-explain {
  font-style: italic;
  font-size: 0.8rem;
}

.absorb.subtext-italic {
  font-style: italic;
  font-size: 0.7rem;
}

.absorb.all-courses-wrapper {
  padding: 1.5rem;
}

.absorb.course-go-button-wrapper {
  justify-content: center !important;
  width: 200px;
}

.absorb.course-go-button {
  height: 100%;
}

.absorb.purchase-tokens {
  padding: 1rem;
}

.absorb.purchase-divider {
  border-top: solid;
}

.absorb.purchase-button-wrapper {
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 0.5rem;
}

.absorb.purchase-header {
  font-size: 1.5rem;
  margin-bottom: -0.1rem;
}

.absorb.course-display {
  /* width: 100vw; */
  margin-top: 0px;
  /* margin-bottom: 100vh; */
}

.absorb.course-button {
  min-width: fit-content;
}

.absorb.course-row {
  min-height: 25vh;
  margin: 1rem;
  padding: 1rem;
  justify-content: space-between;
  background-color: rgb(25 135 84);
  display: flex;
  /* flex-direction: column; */
}

.absorb.course-row-wrapper {
  min-width: fit-content;
}

.absorb.go-button {
  width: 100%;
  right: 0px;
}

.absorb.disclaim-footer {
  font-style: italic;
  width: 97vw;
  bottom: 0px;
}

.mission-statement-header {
  text-align: left;
  margin-left: 2rem;
  color: #4350a2;
}

.mission-statement-text {
  margin: auto;
  text-align: left;
  margin-left: 2rem;
}

.logo {
  padding: 50px;
}

.logo-profile {
  margin-left: 20px;
}

.onboard.quote-header {
  font-weight: bold;
  font-size: 1.8rem;
  margin-right: 12vw;
}

.onboard.quoted-price {
  margin-left: auto;
  margin-right: auto;
  padding-inline: 2rem;
}

.onboard.quote-card {
  width: 56%;
  margin-left: auto;
  margin-right: auto;
}

.form-background {
  background: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 1px 1px 3px 3px #ccc;
  width: 20rem;
  margin: auto;
}

.payment-background {
  background: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 1px 1px 3px 3px #ccc;
  width: 18rem;
  margin: auto;
}

.btn.btn-danger.logout {
  float: right;
  margin: 10px;
}

.btn.btn-success.language {
  float: right;
  margin: 10px;
}

.profile-logo {
  margin-left: 20px;
  width: 8rem;
}

.acteducate-link {
  font-size: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  color: #464646;
}

.logo-header {
  display: flex;
}

.link-placement {
  display: flex;
  text-decoration: none !important;
}

.bold-header {
  color: #464646;
  font-weight: bold;
  margin-left: 10px;
  text-align: left;
}

.sub-info {
    display: flex;
    text-align: left;
    margin-left: 20px;
    font-weight: 200;
    font-size: 1.2 rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.profile-field {
  display: inline-block;
}

.row.license-buttons {
  margin: auto;
}

.profile-information {
  background: white;
  border-radius: 5px;
  height: auto;
  border: 1px solid #ccc;
}

.courses-button {
  display: grid;
}

.tbody-style {
  display: flex;
}

.button-spacing {
  display: block;
  margin-top: 10px;
}

.course-edit-container {
  margin: auto;
  width: 90%;
}

.display-4.font-weight-normal.left {
  text-align: left;
  margin: 20px;
}

.display-4.font-weight-normal.header {
  border-bottom: 2px solid grey;
  display: flow-root;
  width: 100%;
}

.lead.course-edits {
  float: left;
}

.success-button {
  margin-top: 20px;
  float: left;
}

.courses {
  margin: auto;
}

.checkbox-form-select {
  display: inline-grid;
  text-align: left;
  margin: 15px;
}

.card-custom {
  width: 50%;
  margin: auto;
}

.license-image {
  margin: auto;
  display: block;
  max-width: 50%;
}

.user-control-buttons {
  margin: auto;
  display: inline-grid;
}

.drag-drop-space {
  height: 200px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.drag-drop-text {
  padding: 75px 0;
}

.even-spacing-buttons {
  justify-content: space-between;
}

.options-placement {
  float: right;
  cursor: pointer;
  display: flex;
}

.custom-news-title {
  display: flex;
  justify-content: space-between;
}

.content-container {
  text-align: justify;
  margin: 0 15px 0 0;
  margin-top: 10px;
}

.job-description {
  text-align: justify;
  margin: 0 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.job-buttons {
  float: left;
  display: flex;
  margin-left: 15px;
}

.homepage-buttons {
  display: grid;
  width: 200px;
  margin: auto;
}

.card.job-card {
  width: 95%;
  float: left;
  padding: 20px;
  margin: 10px;
}

.text-left {
  text-align: left;
}

.industry {
  display: flex;
}

.card-height {
  height: 500px;
}

.container.notifications {
  margin: auto;
}

.button-display {
  display: contents;
}

.corner-button {
  float: right;
}

/* Learn More */

.cert-manage {
  text-align: initial;
}

.absorb-courses {
  margin-right: 5vw;
  margin-left: 5vw;
}

.popup-auto-width {
  min-width: fit-content;
}

.popup-scrollable {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: fit-content;
}

.popup-scrollable::-webkit-scrollbar {
  margin-top: 10px;
  background-color: transparent;
  border-radius: 2px;
  width: 15px;
}

.popup-scrollable::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-right: 10px solid white;
  border-top: 10px solid white;
  border-bottom: 10px solid white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 5px;
}

@media (min-width: 1150px) {
  .licenses-card-container {
    justify-content: center;
    display: inline-flex;
  }

  .navbar-nav.mobile-nav {
    text-align: left;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .buttons {
    width: 20%;
    float: right;
  }

  .button-spacing {
    margin-left: 15px;
  }

  .card-container.news {
    width: 50rem;
    margin: auto;
  }

  .card-body.half-container {
    display: flex;
  }

  .cert-requirements {
    width: 50%;
  }

  .card.job-card {
    width: 25%;
    float: left;
    padding: 20px;
    margin: 10px;
  }

  .margin-right {
    margin-right: 10px;
  }

  .container.notifications {
    display: flex;
  }

  .row.one {
    width: 75%;
  }

  .row.two {
    width: 75%;
  }

  .active-jobs {
    width: 25rem;
  }

  .jobs-break {
    display: flex;
  }

  .active-row-expanded {
    width: 100%;
  }

  .applied-jobs-container {
    width: 25rem;
  }

  .payment-break {
    width: 100%;
    display: flex;
  }

  .payment-info {
    width: 50%;
  }

  .homepage-buttons {
    /* display: contents; */
    width: 200px;
    margin: auto;
  }

  .absorb.course-display {
    /* margin: 1rem;
    padding: 1rem; */
    margin-top: 0px;
  }

  .absorb.course-row {
    width: 25%;
    float: left;
    margin: 1rem;
    padding: 1rem;
    justify-content: space-between;
    background-color: rgb(25 135 84);
    color: white;
  }

  .absorb.course-header {
    width: 80%;
    display: flex;
    margin-bottom: 0px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 900px) {
  .licenses-card-container {
    display: grid!important;
  }
}

@media (max-width: 650px) {
  .absorb.course-header {
    display: inline;
    margin-bottom: 2rem;
    justify-content: space-between;
  }

  .absorb.header-controls {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
    display: flex;
    justify-content: space-between;
  }

  .absorb.all-courses-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
  }

  .licenses-card-container {
    display: block !important;
  }

  .licenses-card {
    width: 90%;
  }

  .cal-fire-images {
    display: block !important;
  }

  .progress-and-button {
    display: contents !important;
  }

  .current-expected {
    display: flex!important;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .current-expected > .lead {
    width: 100%;
    justify-content: space-between;
  }

  .current-module {
    width: auto !important;
  }

  .module-name {
    width: auto !important;
  }

  .apprentice-program {
    display: block !important;
  }
}

.applied-row {
  height: 40px;
  width: 400px;
}

.applied-row-expanded {
  height: auto;
  width: 400px;
}

.active-row {
  height: 40px;
  display: block;
}

.active-row-expanded {
  height: auto;
  text-align: left;
}

.admin-row {
  height: 40px;
  width: 400px;
}

.admin-row-expanded {
  height: auto;
  width: 400px;
}

/* Jobs */

.active-jobs {
  margin-left: 10px;
}

.active-jobs-title {
  display: contents;
}

.licenses-card-container {
  justify-content: center;
}

.licenses-card {
  width: 95%;
}

.licenses-card-right {
  width: 95%;
}

.cal-fire-images {
  display: flex;
}

.progress-and-button {
  display: flex;
  align-items: flex-end;
}

.progress {
  display: absolute !important;
}

.progress-bar {
  top: 0px;
  height: 50%;
  width: 100%;
}

.current-expected {
  display: inline-flex;
  justify-content: space-around;
}

.apprentice-text {
  text-align: left;
  max-width: 500px;
}

.licenses-and-ceu {
  min-width: 280px;
  width: 100%;
  padding-right: 0.5rem;
}

.current-module {
  width: 25%;
}

.module-name {
  width: 50%;
}

.apprentice-program {
  display: flex;
}

.left-btn {
  right: 0px;
}

.btn-group {
  justify-content: space-between;
}

.btn-group > .left {
  margin-right: 2rem;
}

.btn-group > .right {
  margin-left: 2rem;
}

.or-dot {
  text-align: center;
  margin-inline: 2vw;
  margin-top: auto;
  margin-bottom: auto;
}
