.apprentice-card {
    height: fit-content;
    max-height: 15vh;
    transition: max-height 800ms ease-out;
}

.apprentice-card.expanded {
    max-height: 50vh;
    box-shadow: 0 0 4px #4450A2;
}

.opaque {
    background-color: rgba(50, 50, 50, 0.2);
    box-shadow: 0 0 5px rgba(50, 50, 50, 0.8);
    backdrop-filter: blur(2px);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.apprentice-page {
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;
}

.apprentice-break {
    width: 15%;
}

.admin-select {
    height: inherit!important;
}

.chevron {
    height: 20px;
    aspect-ratio: 1;
}

.apprentice-ojl-button {
    width: 20vw;
}

.table-apprentice {
    display: inherit;
    text-decoration: none !important;
    justify-content: space-between;
    width: 30vw;
    white-space: wrap;
}

.table-apprentice > p {
    text-align: right;
}

.apprentice-table-wrapper {
    line-height: 1.2rem;
}

.apprentice-table {
    justify-content: space-between;
}

.apprentice-table-notes {
    justify-content: space-evenly;
}

.tall {
    min-height: 95vh;
}

.under-tab {
    background-color: grey;
}

.courses-list {
    flex-direction: row;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: left;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.courses-filter {
    margin: 10px;
}

.student-info {
    display: flex;
    text-align: left;
}

.id-position {
    width: 100%;
    align-self: center;
}

.progress-info {
    column-count: 3;
    text-align: left;
}

.dashboard-info-flex-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dashboard-info-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.progress-container {
    display: flex;
}

.dashboard-sub-field {
    display: flex;
    justify-content: space-around;
}

.dashboard-sub-info {
    margin-left: 3rem;
}

.dashboard-table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.dashboard-progress-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1vh;
    margin-left: 2%;
}

.dashboard-progress-row.embedded {
    margin-left: 0;
    margin-bottom: 0;
}

.dashboard-table-item {
    width: 28vw;
    display: flex;
    padding-right: 2vw;
    margin-bottom: 1vh;
    justify-content: space-between;   
}

.dashboard-table-item > b {
    white-space: nowrap;
}

.dashboard-table-item > p {
    text-align: right;
}

.dashboard-table-item.contents {
    display: grid!important;
}

.dashboard-table-item.w-48 {
    width: 48%;
}

.input-group.apprentice {
    justify-content: end;
}

@media screen and (max-width: 700px) {
    .dashboard-table-item {
        width: 100%!important;
    }

    .dashboard-table-item.contents {
        display: contents!important;
    }

    .tab-title.apprentice {
        display: none;
    }    

    .input-group.apprentice {
        width: 70%!important;
    }
}

@media screen and (max-width: 1120px) {
    .dashboard-table-item {
        width: 48%;
    }
}

@media screen and (max-width: 1150px) {
    .apprentice-break {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .input-group.apprentice {
        display: flex!important;
        flex-wrap: nowrap;
    }

    .admin-select {
        width: 50%!important;
    }

    .apprentice-table-wrapper {
        display:contents!important;
    }

    .table-apprentice {
        width: 100%!important;
        display: flex;
    }
    .table-apprentice > p {}
}



@media (max-width: 1000px) {
    .progress-container {
        display: inline;
    }

    .dashboard-sub-field {
        margin-left: 2rem;
        justify-content: start;
    }

    .input-group.apprentice {
        flex-direction: column-reverse;
    }

    .admin-select {
        width: 100%!important;
    }

    .collapsed-apprentice-row {
        width: 100%!important;
    }

    .collapsed-apprentice-row > div {
        justify-content: space-between;
        margin-right: 0.75rem;
    }
}