
.jobs-feed {
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;
}


.job-title.cropped {
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.job-description-cropped {
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.job-table-row {
    display: flex;
    flex-wrap: wrap;
}

.job-table-item {
    margin-left: .75rem;
    display: flex;
    justify-content: space-between;
    width: 27vw;
}

.job-table-item>strong {
    white-space: nowrap;
}

.job-table-item>p {
    text-align: right;
}

.job-row {
    height: auto;
    overflow: hidden;
    max-height: 100px;
    margin-bottom: 0.75rem;
    transition: max-height 800ms ease-in-out;
}

.job-row.expanded {
    padding-left: 0px;
    padding: 25px;
    height: auto;
    max-height: 10000px;
}

.all-job-search {
    display: flex;
    width: min(350px, 40vw);
}

.all-job-search>input {
    height: inherit;
}

.description {
    width: 100% !important;
    margin-right: 0.75rem;
}

.all-job-search>input {
    width: 100% !important;
}

.search-export-inputs {
    margin: auto;
    display: flex;
    height: 50px;
}

.filter-include-certs {
    width: 25%;
}

.filter-check {
    width: min-content !important;
    margin-top: 2px;
}

.requirement-filter {
    width: fit-content !important;
    padding-right: 2em !important;
    margin-right: 0.75rem;
}

.job-flex-3 {
    flex: 3 !important;
}

.job-flex-1 {
    flex: 1 !important;
}

.jobs-flex-row {
    flex-direction: row;
}

@media screen and (max-width: 600px) {
    .job-title.cropped {
        max-width: 69%;
    }

    .job-description-cropped {
        display: none;
    }

    .all-job-header {
        display: block !important;
        width: 100% !important;
    }

    .all-job-search {
        width: 100%;
    }

    .applicant-acceptance {
        flex-direction: column;
    }

    .applicant-acceptance>div {
        width: 100% !important;
    }
}

@media screen and (max-width: 800px) {
    .job-table-item {
        width: 100% !important;
        margin-left: 0;
    }


    .description {
        margin-right: 0;
    }

    .job-title {
        margin-left: 0;
    }
}

@media screen and (max-width: 1250px) {
    .job-flex-3 {
        flex: 1 !important;
    }
    
    .job-flex-1 {
        flex: 1 !important;
    }    
    
    .job-table-item {
        width: 48%;
    }
    
    .jobs-flex-row {
        flex-direction: column;
    }
}